import React, { useContext, useState } from 'react';
import styles from "./style.module.css";
import languages from '../../localization';
import {AuthContext} from "../../authContext";
import CurrencyDropdown from "../CurrencyDropdown";
import CashValidator from "../../validators/CashInOut";
import serverPath, { khataServer } from '../../utils/serverPath';
import useStore from '../../store/store';
import CustomersDropdown from '../CustomersDropdown';
import SweetAlert from '../SweetAlert';
import createValidForm from "../../utils/createValidForm";
import Voucher from "../Voucher";

const CashIn_Out = (props) =>
{
	const context = useContext(AuthContext);
	const initState = {
		amount: "",
		profit: '',
		currencyId: context.currency.id,
		cashbookId: props.cashbookId,
		information: "",
		photo: "",
		type: props.type,
		error: "",
	}

	const [ fields, setFields ] = useState(initState);
	const [ globalState, dispatch ] = useStore();


	const onChange = (value, type) =>
	{
		setFields(prev => ({
			...prev,
			[type]: value,
		}));
	};

	const submitHandler = async (voucher) =>
	{
		let requestData = {
			amount: fields.amount,
			profit: fields.profit ? fields.profit : 0,
			currencyId: fields.currencyId,
			information: fields.information,
			photo: fields.photo,
			// providerId: context.user.id,
			cashbookId: fields.cashbookId,
			type: props.type ? true : false,
			isReceivedMobile: false,
		};

		const error = CashValidator(requestData);

		if (error)
		{
			onChange(error, "error");
			return;
		}

		if (props.fromCashbook && !fields.cashbookId)
			return onChange("Please select a customer!", "error");

		const form = createValidForm(requestData);

		const response = await fetch(serverPath("/transaction_file"), {
			method: "POST",
			body: form
		});

		const objData = await response.json();
		if (objData.status === "success")
		{
			dataManager(objData);
			if (voucher === true)
				window.print();
		}

		if (objData.status === "failure")
		{
			onChange(objData.message, "error");
			return;
		}
	}

	const dataManager = async (objData) =>
	{
		let cloneCustomers = [...globalState.customers];
		let cashBookIndex = cloneCustomers.findIndex(per => per.id == fields?.cashbookId);
		if(cashBookIndex < 0)
			return SweetAlert("info", "PLease Try Again!")
		
		let cloneSummary = [...cloneCustomers[cashBookIndex]?.summary];
		let summaryIndex = cloneSummary.findIndex(per => per.currencyId == fields.currencyId);
		if(summaryIndex < 0 && cloneSummary?.length > 0)
		{
			summaryIndex = cloneSummary?.length;
			cloneSummary = [...cloneSummary, {cashIn: 0, cashOut: 0, currencyId: fields.currencyId, totalProfit: 0, cashbookId: cloneCustomers[cashBookIndex]?.id }]
		}
		if(summaryIndex < 0  && cloneSummary?.length <= 0)
		{
			summaryIndex = 0;
			cloneSummary = [{cashIn: 0, cashOut: 0, currencyId: fields.currencyId, totalProfit: 0, cashbookId: cloneCustomers[cashBookIndex]?.id }]
		}

		let In_Out_Amount = cloneSummary[summaryIndex][props.type ? "cashIn" : "cashOut"];
		let newAmount = (Number.parseInt(In_Out_Amount) + Number.parseInt(fields.amount))
		let totalProfit = (Number.parseInt(cloneSummary[summaryIndex].totalProfit) + Number.parseInt(fields.profit))
		cloneSummary[summaryIndex][props.type ? "cashIn" : "cashOut"] = newAmount;
		cloneSummary[summaryIndex].totalProfit = totalProfit;
		cloneCustomers[cashBookIndex].summary = cloneSummary;

		dispatch("setData", {type: "customers", data: cloneCustomers});
		// if (context.currency?.id === fields.currencyId)
		dispatch("setData", {type: "transactions", data: [...globalState.transactions, objData.data[0]]});

		setFields(initState);
		props.cancel();
	}

	const dismissHandler = () =>
	{
		setFields(initState);
		props.cancel();
	}

	if (!props.visible)
		return;

	const currencyChangeHandler = (currency) =>
	{
		onChange(currency.id, "currencyId");
	}

	const customerChangeHandler = (customer) =>
	{
		onChange(customer.summary[0].cashbookId || customer.id, "cashbookId");
	}

    return (
		<>
			{props.visible && (
				<div className={[styles.container, "modal show "].join(" ")} style={{ display: 'block' }} tabIndex="-1">
					<div className={["modal-dialog justify-content-center", styles.content].join(" ")}>
						<div className="modal-content m-auto">
							<div className="p-3">
								<p className={props.type ? "text-success" : "text-danger"}>{props.type ? "Add Cash In" : "Add Cash Out"}</p>
								<div>
									<div className={[styles.form, "row px-2"].join(" ")}>
										<input type='number' style={props.type ? {border: "1px solid green"} : {border: "1px solid rgba(255, 0, 0, 0.5)"}} placeholder='Amount' value={fields.amount} onChange={({target}) => onChange(target.value, "amount")} />
										<CurrencyDropdown currencyChangeHandler={(currency) => currencyChangeHandler(currency)} className={styles.cDropdown} dropdownContentClassName={styles.cDropdownContent} />
										{
											props.fromCashbook &&
											<CustomersDropdown customerChangeHandler={(customer) => customerChangeHandler(customer)} className={styles.cDropdown} dropdownContentClassName={styles.cDropdownContent} />
										}
										<input type='number' style={props.type ? {border: "1px solid green"} : {border: "1px solid rgba(255, 0, 0, 0.5)"}} placeholder='Profit' value={fields.profit} onChange={({target}) => onChange(target.value, "profit")} />
										<input type='file' style={props.type ? {border: "1px solid green"} : {border: "1px solid rgba(255, 0, 0, 0.5)"}} placeholder='Photo' onChange={({target}) => onChange(target.files[0], "photo")} />
										<input type='text' style={props.type ? {border: "1px solid green"} : {border: "1px solid rgba(255, 0, 0, 0.5)"}} placeholder='Information' value={fields.information} onChange={({target}) => onChange(target.value, "information")} />
									</div>
									<p className='text-danger'>{fields.error.length >= 1 && fields.error}</p>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={dismissHandler}>Dismiss</button>
								<button type="button" className={props.type ? "btn btn-success col-3" : "btn btn-danger col-3"} onClick={submitHandler}>Submit</button>
								<button type="button" className={props.type ? "btn btn-success col-4" : "btn btn-danger col-4"} onClick={() => submitHandler(true)}>Submit & Voucher</button>
							</div>
						</div>
					</div>
					<Voucher data={{amount: fields.amount, type: props.type, cashbookId: fields.cashbookId}} />
				</div>
			)}
		</>
    )
};

export default CashIn_Out;