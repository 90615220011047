import React from "react";
import styles from "./style.module.css";
import { useContext } from "react";
import { AuthContext } from "../../authContext";
import PaymentModal from "../PaymentModal";
import { useState } from "react";
import AddPayment from "../AddPayment";
import AskAlert from "../AskAlert";
import { khataServer } from "../../utils/serverPath";
import { useEffect } from "react";
import useStore from "../../store/store";
import SortData from "../../utils/SortData";

const PaymentSchedule = (props) =>
{
	const context = useContext(AuthContext);
	const [globalState, dispatch] = useStore();
	const [paymentModalVisible, setPaymentModalVisible] = useState({visible: false, id: 1});
	const [addPaymentVisible, setAddPaymentVisible] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() =>
	{
		if (globalState.payments.length >= 1)
			setData(globalState.payments);

	}, [globalState.payments]);


	const deletehandler = async (id) =>
	{
		const sure = await AskAlert({text: "Are you sure to delete this!"})
		if (!sure)
			return;

		const response = await fetch(khataServer("/payment"), {
			method: "DELETE",
			headers: {
				"Content-Type": "Application/JSON",
			},
			body: JSON.stringify({ id, providerId: context.user.id }),
		});
		
		const objData = await response.json();
		if (objData.status === "failure")
			return;

		if (objData.status === "success")
		{
			const paymentsClone = [...globalState.payments];
			const newPayments = paymentsClone.filter(pay => pay.id !== id);
			setData(newPayments);
			dispatch("setData", {type: "payments", data: newPayments});
			setPaymentModalVisible({visible: false, id: ""});
		}

	}

	return (
		<div className="my-3" style={{backgroundColor: "white", padding: 10, borderRadius: 5}}>
			{/* <div className="row d-flex m-0 mt-3 align-items-center justify-content-between">
					<p className="col-lg-7 col-3">Payment schedule</p>
					<p className={[styles.cursor_pointer, "col-lg-2 col-3"].join(" ")} onClick={() => setAddPaymentVisible(true)}>Add</p>
			</div> */}
			<div className="row m-0 mt-3">
					<p>Payment schedule</p>
			</div>

			{
				data.length >= 1 ?
					SortData(data).reverse().map((value, index) => (
							<div
								className={["row d-flex m-0 align-items-center justify-content-between", styles.cursor_pointer].join(" ")}
								key={value.id}
								onClick={() => setPaymentModalVisible({visible: true, id: value.id})}
							>
									<p className={["col-lg-6 col-3", value?.type ? "text-success" : "text-danger"].join(" ")}>{new Date(value.dateTime).toLocaleDateString()}</p>
									<p className={["col-lg-4 col-4", value?.type ? "text-success" : "text-danger"].join(" ")}>{value.amount} {value.currencyCode}</p>
							</div>
					))
				:
						<div className="text-center">
							<p className="fs-5">No Payments</p>
						</div>
			}

			<PaymentModal
				visible={paymentModalVisible.visible}
				id={paymentModalVisible.id}
				cancel={() => setPaymentModalVisible({visible: false, id: ""})}
				deletehandler={(id) => deletehandler(id)}
			/>
			<AddPayment
				visible={addPaymentVisible}
				cancel={() => setAddPaymentVisible(false)}
				submitHandler={() => setAddPaymentVisible(false)}
			/>
	</div>
	)
};

export default PaymentSchedule;